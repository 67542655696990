// import React, { useEffect, useState } from "react";
// import { Stack, Grid, Typography, MenuItem, Select } from "@mui/material";
// import Input from "../UI/Input/Input";
// import fcb from "../../assets/socialfcb.png";
// import insta1 from "../../assets/socialtel.png";
// import insta2 from "../../assets/socialinsta.png";
// import figma from "../../assets/socialfigma.png";
// import Button from "../UI/Button/Button";
// import classes from "./Register.module.css";
// import { init, sendForm } from "emailjs-com";

// function Register2() {
//   /*user field management hooks  */
//   const [firstName, setFirstName] = useState("");
//   const [isFirstNameValid, setIsFirstNameValid] = useState(true);

//   const [email, setEmail] = useState("");
//   const [isEmailValid, setIsEmailValid] = useState(true);

//   // const [phone, setPhone] = useState(countryCode);
//   const [isPhoneValid, setIsPhoneValid] = useState(true);

//   const [countryCode, setCountryCode] = useState("");
//   const [mobileNumber, setMobileNumber] = useState("");
//   const [countryCodes, setCountryCodes] = useState([]);

//   const [message, setMessage] = useState("");

//   const [isLoading, setIsLoading] = useState(false);
//   const [submitEmail, setSubmitEmail] = useState(false);

//   init("-p2RXdf2m6LpT57eE"); //emailjs Public id, acquired from emailjs for nowra

//   useEffect(() => {
//     const fetchCountryCodes = async () => {
//       try {
//         const response = await fetch("https://restcountries.com/v3.1/all");
//         const data = await response.json();

//         const codes = data
//           .filter(
//             (country) => country.name.common !== "Cocos (Keeling) Islands"
//           )
//           .map((country) => ({
//             code:
//               country.idd.root +
//               (country.idd.suffixes ? country.idd.suffixes[0] : ""),
//             name: country.name.common,
//           }))
//           .filter((country) => country.code);

//         setCountryCodes(codes);
//         setCountryCode(codes[0]?.code || ""); // Set default country code
//       } catch (error) {
//         console.error("Error fetching country codes:", error);
//       }
//     };

//     fetchCountryCodes();
//   }, []);

//   const [selectedCountry, setSelectedCountry] = useState("+61");

//   const firstnameChangeHandler = (e) => {
//     if (e.target.value.trim().length === 0 || e.target.value.length > 24) {
//       setIsFirstNameValid(false);
//     } else {
//       setIsFirstNameValid(true);
//     }
//     setFirstName(e.target.value);
//   };
//   const emailChangeHandler = (e) => {
//     if (e.target.value.trim().length === 0 || !e.target.value.includes("@")) {
//       setIsEmailValid(false);
//     } else {
//       setIsEmailValid(true);
//     }
//     setEmail(e.target.value);
//   };
//   const phoneChangeHandler = (e) => {
//     if (e.target.value.trim().length === 0 || +e.target.value < 0) {
//       setIsPhoneValid(false);
//     } else {
//       setIsPhoneValid(true);
//     }
//     setMobileNumber(e.target.value);
//   };
//   const handleCountryCodeChange = (event) => {
//     setCountryCode(event.target.value);
//   };
//   const messageChangeHandler = (e) => {
//     setMessage(e.target.value);
//   };

//   const countryChangehandler = (e) => {
//     setSelectedCountry(e.target.value ? e.target.value : "+61");
//   };

//   /* form submission handler */
//   const submitHandler = (e) => {
//     e.preventDefault();
//     const fullPhoneNumber = `${selectedCountry}${mobileNumber}`;
//     let submit = true;

//     //FIELD VALIDATION LOGIC prior to submission, may need more rigorous validation later
//     if (firstName.trim().length === 0 || firstName.length > 24) {
//       setIsFirstNameValid(false);
//       submit = false;
//     }

//     if (!email || !email.includes("@")) {
//       setIsEmailValid(false);
//       submit = false;
//     }

//     if (
//       !fullPhoneNumber ||
//       +fullPhoneNumber < 0 ||
//       !mobileNumber ||
//       +mobileNumber < 0
//     ) {
//       setIsPhoneValid(false);
//       submit = false;
//     }

//     if (submit) {
//       setIsLoading(true);
//       const combinedMessage = `
//       Phone: ${fullPhoneNumber + "\n"}
//       \n\n
//       Email: ${email}
//       \n
//       `;
//       console.log(combinedMessage);
//       const formElement = e.target;

//       // Set the combined message in the form element
//       const messageInput = formElement.querySelector("#message");
//       messageInput.value = combinedMessage;
//       // is this a testing email? if so, send to test email, else send to real email
//       // sendForm(
//       //   "service_w4gcbf7", //service key from Emailjs dashboard for nowra  service_00qirhq
//       //   "template_9u3skqf", //template key from Emailjs dashboard for nowra
//       //   formElement,
//       //   "-p2RXdf2m6LpT57eE" //the form
//       // )
//       //   .then((result) => {
//       //     setIsLoading(false);
//       //     setSubmitEmail(true);

//       //     alert("Email sent successfully:");
//       //   })
//       //   .catch((error) => {
//       //     alert("Error sending email:", error);
//       //   });
//     }
//   };

//   useEffect(() => {
//     if (submitEmail) {
//       setEmail("");
//       setMobileNumber("");
//       setFirstName("");
//       setMessage("");
//       setSubmitEmail(false);
//     }
//   }, [submitEmail]);

//   return (
//     <Stack id="register" px={{ xs: 4, md: 10 }} backgroundColor="#000" pt={22}>
//       <Grid
//         container
//         rowSpacing={{ xs: 6, sm: 12, md: 16 }}
//         columnSpacing={{ xs: 1, sm: 12, md: 18 }}
//       >
//         {/*this should be in a separate componet */}
//         <Grid item xs={12} sm={6} md={6} lg={6}>
//           <Stack flexDirection="column" height="70%">
//             <Typography variant="h6" mb={2} color="#95f0a4">
//               PROVEN EXPERIENCE
//             </Typography>
//             <Typography variant="body1" mb={2} color="white">
//               Nowra Business Park is the innovative workspace you’ve been
//               looking for, a brand new business prospect in the thriving
//               industrial and commercial centre that is South Nowra.
//             </Typography>
//             <Stack flexDirection="row" className={classes.icons}>
//               <a
//                 href="https://www.facebook.com/nowrabusinesspark/"
//                 target="_blank"
//                 style={{marginRight:"-20px"}}
//               >
//                 <img src={fcb} alt="facebook" width="80px" height="80px" />
//               </a>
//               <a
//                 href="https://www.instagram.com/nowrabusinesspark/"
//                 target="_blank"
//               >
//                 <img src={insta2} alt="instagram" width="80px" height="80px" />
//               </a>
//               {/* <img src={insta1} alt="insta_direct" width="80px" height="80px" /> */}
//             </Stack>
//           </Stack>
//         </Grid>

//         <Grid item xs={12} sm={6} md={6} lg={6}>
//           <form onSubmit={submitHandler}>
//             <Stack flexDirection="column" alignItems="flex-start">
//               <Typography variant="h6" color="#95f0a4" mb={2}>
//                 REGISTER YOUR INTEREST
//               </Typography>

//               <Input
//                 className={`${classes.input} ${
//                   !isFirstNameValid && classes.invalid
//                 }`}
//                 input={{
//                   type: "text",
//                   id: "first_name",
//                   placeholder: "Name",
//                   name: "first_name",
//                   onChange: firstnameChangeHandler,
//                   value: firstName,
//                 }}
//               />

//               <Input
//                 className={`${classes.input} ${
//                   !isEmailValid && classes.invalid
//                 }`}
//                 input={{
//                   type: "email",
//                   id: "email",
//                   name: "email",
//                   placeholder: "Email",
//                   onChange: emailChangeHandler,
//                   value: email,
//                 }}
//               />

//               <div className={classes.numberContainer}>
//                 <Select
//                   labelId="countryCode"
//                   id="countryCode"
//                   label="country code"
//                   onChange={countryChangehandler}
//                   value={selectedCountry}
//                   sx={{
//                     color: "white",
//                     ".MuiSelect-icon": {
//                       color: "white",
//                     },
//                     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "white",
//                     },
//                     "& .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "white", //
//                     },
//                   }}
//                 >
//                   {countryCodes.map((country, idx) => (
//                     <MenuItem
//                       key={`${country.code}-${idx}`}
//                       value={country.code}
//                     >
//                       {country.name} ({country.code})
//                     </MenuItem>
//                   ))}
//                 </Select>

//                 <Input
//                   className={`${classes.input} ${
//                     !isPhoneValid && classes.invalid
//                   }`}
//                   input={{
//                     type: "number",
//                     id: "phone_number",
//                     name: "phone_number",
//                     placeholder: "Mobile",
//                     onChange: phoneChangeHandler,
//                     value: mobileNumber,
//                   }}
//                 />
//               </div>

//               <Input
//                 input={{
//                   type: "text",
//                   id: "message",
//                   name: "message",
//                   placeholder: "Message",
//                   value: message,
//                   onChange: messageChangeHandler,
//                 }}
//               />

//               <textarea
//                 placeholder="Message"
//                 id="message"
//                 name="message"
//                 onChange={messageChangeHandler}
//                 value={message}
//                 style={{ display: "none" }}
//               />

//               <Stack>
//                 <Button type="submit">
//                   {isLoading ? "Submitting" : "Submit"}
//                 </Button>
//               </Stack>
//             </Stack>
//           </form>
//         </Grid>
//       </Grid>
//       <p className={classes.parag}>© 2024 All Rights Reserved</p>
//     </Stack>
//   );
// }

// export default Register2;

import React, { useEffect, useState } from "react";
import { Stack, Grid, Typography, MenuItem, Select } from "@mui/material";
import Input from "../UI/Input/Input";
import fcb from "../../assets/socialfcb.png";
import insta2 from "../../assets/socialinsta.png";
import Button from "../UI/Button/Button";
import classes from "./Register.module.css";
import { init, send } from "emailjs-com";

function Register2() {
  /*user field management hooks  */
  const [firstName, setFirstName] = useState("");
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);

  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [countryCode, setCountryCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);

  const [message, setMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [submitEmail, setSubmitEmail] = useState(false);

  init("7xy_1MSwMJM4qvUFQ"); // Replace with your EmailJS Public key

  useEffect(() => {
    const fetchCountryCodes = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();

        const codes = data
          .filter(
            (country) => country.name.common !== "Cocos (Keeling) Islands"
          )
          .map((country) => ({
            code:
              country.idd.root +
              (country.idd.suffixes ? country.idd.suffixes[0] : ""),
            name: country.name.common,
          }))
          .filter((country) => country.code);

        setCountryCodes(codes);
        setCountryCode(codes[0]?.code || ""); // Set default country code
      } catch (error) {
        console.error("Error fetching country codes:", error);
      }
    };

    fetchCountryCodes();
  }, []);

  const [selectedCountry, setSelectedCountry] = useState("+61");

  const firstnameChangeHandler = (e) => {
    if (e.target.value.trim().length === 0 || e.target.value.length > 24) {
      setIsFirstNameValid(false);
    } else {
      setIsFirstNameValid(true);
    }
    setFirstName(e.target.value);
  };
  const emailChangeHandler = (e) => {
    if (e.target.value.trim().length === 0 || !e.target.value.includes("@")) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
    setEmail(e.target.value);
  };
  const phoneChangeHandler = (e) => {
    if (e.target.value.trim().length === 0 || +e.target.value < 0) {
      setIsPhoneValid(false);
    } else {
      setIsPhoneValid(true);
    }
    setMobileNumber(e.target.value);
  };
  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };
  const messageChangeHandler = (e) => {
    setMessage(e.target.value);
  };

  const countryChangehandler = (e) => {
    setSelectedCountry(e.target.value ? e.target.value : "+61");
  };

  /* form submission handler */
  const submitHandler = (e) => {
    e.preventDefault();
    const fullPhoneNumber = `${selectedCountry}${mobileNumber}`;
    let submit = true;

    //FIELD VALIDATION LOGIC prior to submission
    if (firstName.trim().length === 0 || firstName.length > 24) {
      setIsFirstNameValid(false);
      submit = false;
    }

    if (!email || !email.includes("@")) {
      setIsEmailValid(false);
      submit = false;
    }

    if (!fullPhoneNumber || +fullPhoneNumber < 0 || !mobileNumber || +mobileNumber < 0) {
      setIsPhoneValid(false);
      submit = false;
    }

    if (submit) {
      setIsLoading(true);

      const formDetails = {
        from_name: firstName,
        message,
        from_email: email,
        from_mobile: fullPhoneNumber,
      };

      send(
        "service_00qirhq", // Your EmailJS service ID
        "template_j2pdhjo", // Your EmailJS template ID
        formDetails,
        "7xy_1MSwMJM4qvUFQ" // Your EmailJS public key
      )
        .then((result) => {
          setIsLoading(false);
          setSubmitEmail(true);
          alert("Email sent successfully!");
        })
        .catch((error) => {
          console.log(error)
          setIsLoading(false);
          alert("Error sending email:", error);
        });
    }
  };

  useEffect(() => {
    if (submitEmail) {
      setEmail("");
      setMobileNumber("");
      setFirstName("");
      setMessage("");
      setSubmitEmail(false);
    }
  }, [submitEmail]);

  return (
    <Stack id="register" px={{ xs: 4, md: 10 }} backgroundColor="#000" pt={22}>
      <Grid
        container
        rowSpacing={{ xs: 6, sm: 12, md: 16 }}
        columnSpacing={{ xs: 1, sm: 12, md: 18 }}
      >
        {/* Information Section */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Stack flexDirection="column" height="70%">
            <Typography variant="h6" mb={2} color="#95f0a4">
              PROVEN EXPERIENCE
            </Typography>
            <Typography variant="body1" mb={2} color="white">
              Nowra Business Park is the innovative workspace you’ve been
              looking for, a brand new business prospect in the thriving
              industrial and commercial centre that is South Nowra.
            </Typography>
            <Stack flexDirection="row" className={classes.icons}>
              <a
                href="https://www.facebook.com/nowrabusinesspar/"
                target="_blank"
                style={{ marginRight: "-20px" }}
              >
                <img src={fcb} alt="facebook" width="80px" height="80px" />
              </a>
              <a
                href="https://www.instagram.com/nowrabusinesspark/"
                target="_blank"
              >
                <img src={insta2} alt="instagram" width="80px" height="80px" />
              </a>
            </Stack>
          </Stack>
        </Grid>

        {/* Registration Form */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <form onSubmit={submitHandler}>
            <Stack flexDirection="column" alignItems="flex-start">
              <Typography variant="h6" color="#95f0a4" mb={2}>
                REGISTER YOUR INTEREST
              </Typography>

              <Input
                className={`${classes.input} ${
                  !isFirstNameValid && classes.invalid
                }`}
                input={{
                  type: "text",
                  id: "first_name",
                  placeholder: "Name",
                  name: "first_name",
                  onChange: firstnameChangeHandler,
                  value: firstName,
                }}
              />

              <Input
                className={`${classes.input} ${
                  !isEmailValid && classes.invalid
                }`}
                input={{
                  type: "email",
                  id: "email",
                  name: "email",
                  placeholder: "Email",
                  onChange: emailChangeHandler,
                  value: email,
                }}
              />

              <div className={classes.numberContainer}>
                <Select
                  labelId="countryCode"
                  id="countryCode"
                  label="country code"
                  onChange={countryChangehandler}
                  value={selectedCountry}
                  sx={{
                    color: "white",
                    ".MuiSelect-icon": {
                      color: "white",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white", //
                    },
                  }}
                >
                  {countryCodes.map((country, idx) => (
                    <MenuItem
                      key={`${country.code}-${idx}`}
                      value={country.code}
                    >
                      {country.name} ({country.code})
                    </MenuItem>
                  ))}
                </Select>

                <Input
                  className={`${classes.input} ${
                    !isPhoneValid && classes.invalid
                  }`}
                  input={{
                    type: "number",
                    id: "phone_number",
                    name: "phone_number",
                    placeholder: "Mobile",
                    onChange: phoneChangeHandler,
                    value: mobileNumber,
                  }}
                />
              </div>

              <Input
                input={{
                  type: "text",
                  id: "message",
                  name: "message",
                  placeholder: "Message",
                  value: message,
                  onChange: messageChangeHandler,
                }}
              />

              <Stack>
                <Button type="submit">
                  {isLoading ? "Submitting" : "Submit"}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
      <p className={classes.parag}>© 2024 All Rights Reserved</p>
    </Stack>
  );
}

export default Register2;

