import React, { useEffect, useState } from "react";
import {
  Stack,
  AppBar,
  useMediaQuery,
  Box,
  Button,
  Toolbar,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Logo from "../../assets/NowraLogoRGBnew.png";
import Collapse from "@mui/material/Collapse";
import { HashLink as Link } from "react-router-hash-link";
import { NavLink, useNavigate } from "react-router-dom";

export default function NavBar() {
  const [checked, setChecked] = useState(false); //for the burger menu please add comments for the code
  const handleShow = () => {
    setChecked((prevOpen) => !prevOpen);
  };
  const isMediumScreen = useMediaQuery("(max-width: 890px)");


  useEffect(() => {
    const handleResize = () => {
      if (!isMediumScreen && checked) {
        setChecked(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    }; //very important to remove the event listener
  }, [checked, isMediumScreen]);

  const pages = [
    { name: "Home", path: "/" },
    { name: "Location", path: "/location" },
    { name: "Design", path: "/design" },
    { name: "Update", path: "/update" },
    { name: "Opportunity", path: "/opportunity" },
    //{ name: "SMSF", path: "/smsf" }, //commented out for now to prevent SMSF page from being shown ON BURGER menu
  ];

  const enquireBtn = {
    display: { xs: "none", sm: "none", md: "block" },
    color: "#000",
    border: " 1px solid rgb(0 198 94)",
    backgroundColor: "rgb(0 198 94)",
    fontWeight: "bold",
    borderBottomRightRadius: "15px",
    borderTopLeftRadius: "15px",
    px: 2,
    py: 1,
    mr: 2,
    fontSize: { xs: "10px", sm: "11px", md: "14px" },
    mb: { xs: 2, sm: 0, md: 0 },
    "&:hover": {
      color: "rgb(0 198 94)",
      border: " 2px solid rgba(149,239,164,0.8)",
      backgroundColor: "none",
    },
  };
  const navigate = useNavigate();

  const handleLogoClick = () =>{
    navigate('/')
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "white", height: "85px", py: 1.5 }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Stack pl={2}>
            <img src={Logo} alt="logo" height="85px" width="200px" style={{cursor: "pointer"}} onClick={handleLogoClick} />
          </Stack>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            <NavLink
              to="/home"
              style={({ isActive }) => {
                return {
                  color: isActive ? "rgb(0 198 94)" : "rgb(7 29 73)",
                  textDecoration: "none",
                };
              }}
            >
              <Box mx={1.9} py={1} fontSize={"1.15rem"}>
                Home
              </Box>
            </NavLink>
            <NavLink
              to="/location"
              style={({ isActive }) => {
                return {
                  color: isActive ? "rgb(0 198 94)" : "rgb(7 29 73)",
                  textDecoration: "none",
                };
              }}
              exact
            >
              <Box mx={1.9} py={1} fontSize={"1.15rem"}>
                Location
              </Box>
            </NavLink>
            <NavLink
              to="/design"
              style={({ isActive }) => {
                return {
                  color: isActive ? "rgb(0 198 94)" : "rgb(7 29 73)",
                  textDecoration: "none",
                };
              }}
              exact
            >
              <Box mx={1.9} py={1} fontSize={"1.15rem"}>
                Design
              </Box>
            </NavLink>
            <NavLink
              to="/update"
              style={({ isActive }) => {
                return {
                  color: isActive ? "rgb(0 198 94)" : "rgb(7 29 73)",
                  textDecoration: "none",
                };
              }}
              exact
            >
              <Box mx={1.9} py={1} fontSize={"1.15rem"}>
                Update
              </Box>
            </NavLink>
            <NavLink
              to="/opportunity"
              style={({ isActive }) => {
                return {
                  color: isActive ? "rgb(0 198 94)" : "rgb(7 29 73)",
                  textDecoration: "none",
                };
              }}
              exact
            >
              <Box mx={1.9} py={1} fontSize={"1.15rem"}>
                Opportunity
              </Box>
            </NavLink>
            {/* <NavLink
              to="/smsf"
              style={({ isActive }) => {
                return {
                  color: isActive ? "rgb(0 198 94)" : "rgb(7 29 73)",
                  textDecoration: "none",
                };
              }}
              exact
            >
              <Box mx={1.9} py={1} fontSize={"1.15rem"}>
                SMSF
              </Box>
            </NavLink> */}
          </Box>
          <Link
            to="#register"
            style={{ color: "rgb(7 29 73)", textDecoration: "none" }}
            smooth
          >{/*only place where hashlink is used  */}
            <Button sx={enquireBtn}>ENQUIRE NOW</Button>
          </Link>
          {!checked ? (
            <Box
              sx={{
                width: "175px",
                display: { xs: "block", sm: "block", md: "none" },
              }}
              textAlign="right"
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleShow}
              >
                <MenuIcon sx={{color: "rgb(0 198 94)"}}/>
              </IconButton>
            </Box>
          ) : (
            <Box
              sx={{
                width: "175px",
                display: { xs: "block", sm: "block", md: "none" },
              }}
              textAlign="right"
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleShow}
              >
                <CloseTwoToneIcon sx={{color: "rgb(0 198 94)"}}/>
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Collapse in={checked}>
        <Box
          sx={{
            width: "100%",
            height: "37vh",
            position: "fixed",
            top: "80px",
            backgroundColor: "rgb(7 29 73)",
            display: { xs: "flex", sm: "flex", md: "none" },
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: 5000,
            py: 4,
          }}
        >
          {pages.map((page, index) => (
            <Link
              key={index}
              to={page.path}
              style={{ color: "white", textDecoration: "none" }}
              smooth
            >
              <Box key={index} fontSize={"1.15rem"}>
                {page.name}
              </Box>
            </Link>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
}

