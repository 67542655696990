import React from "react";
import SMSFContent from "./SMSFContent";
import SMSFSwitch from "./SMSFContentSwitch";
import img1 from "../../assets/locationReplaced.jpg";

function SMSFList() {
  return (
    <div id="toscroll">
      <SMSFContent
        title="Investment Strategy"
        text="Before investing in any asset, including an industrial warehouse project, it is crucial to establish an investment strategy for your SMSF. The investment strategy should outline your financial goals, risk tolerance, and how the investment aligns with the fund's objectives."
        source={img1}
        title2="Sole Purpose Test"
        text2="The investments made by an SMSF must meet the sole purpose test, which means they must be for the sole purpose of providing retirement benefits to the members or their dependents."
        source2={img1}
      />
      <SMSFSwitch
        title="Trustee Responsibilities"
        text=" As a trustee of an SMSF, you have legal responsibilities, including the duty to act in the best interest of the fund's members. It is essential to conduct thorough due diligence on the industrial warehouse project before making any investment decisions."
        source={img1}
        title2="Compliance and Regulations"
        text2="SMSFs are subject to various regulations and compliance requirements set by the Australian Taxation Office (ATO). It is essential to familiarize yourself with these rules to ensure that your investment in the industrial warehouse project complies with all relevant regulations."
        source2={img1}
      />
      <SMSFContent
        title="Property Acquisition"
        text="When acquiring property through an SMSF, there are specific rules to follow. For example, the property must be purchased on an arm's length basis, and it cannot be acquired from a related party of the SMSF's members."
        source={img1}
        title2="Financing"
        text2="If you require financing for the industrial warehouse project, it is possible for an SMSF to borrow money to fund the investment through a Limited Recourse Borrowing Arrangement (LRBA). However, strict rules apply to LRBA structures, and it is advisable to seek professional advice to ensure compliance."
        source2={img1}
      />
      <SMSFSwitch
        title="Rental Income and Expenses"
        text="Any rental income received from the industrial warehouse project will generally be taxed at the concessional SMSF tax rate. Additionally, expenses related to the property, such as maintenance, insurance, and property management fees, can be claimed as deductions."
        source={img1}
        title2="Diversification"
        text2="It is generally recommended to have a diversified investment portfolio within your SMSF to manage risk effectively. Consider how the industrial warehouse project fits into your overall investment strategy and the impact it may have on portfolio diversification."
        source2={img1}
      />
    </div>
  );
}

export default SMSFList;
