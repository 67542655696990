import React, { useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import Register from "../../components/Register/Register2";
import classes from "./Design.module.css";
import DesignText from "../../components/Design/DesignText/DesignText";
import BackButton from "../../components/UI/BackToTop/BackButton";
// import DesignCarousel from "../../components/Design/DesignCarousel/DesignCarousel";
import DesignTab from "../../components/Design/DesignTab/DesignTab";
// import SMSF from "../../components/Design/SMSF/SMSF";

function Design() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavBar />
      <DesignTab />

      {/* <div className={classes.bg_design}></div> */}
      <DesignText />
      <div className={classes.bg_design}></div>

      {/* <DesignCarousel /> */}
      {/* <DesignTab /> */}
      {/* <SMSF /> */}
      <Register />
      <BackButton />
    </>
  );
}

export default Design;
