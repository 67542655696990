import React from "react";
import { Grid, Typography } from "@mui/material";

function DesignText() {
  return (
    <>
      <Grid
        container
        py={{ xs: 6, sm: 4, md: 14 }}
        px={{ xs: 6, sm: 6, md: 10 }}
        columnSpacing={12}
        rowSpacing={4}
        backgroundColor="rgb(25 25 25)"
      >
        <Grid item xs={12} sm={6} md={6}>
          {" "}
          <Typography
            variant="h4"
            color="rgb(0 198 94)"
            fontSize={{ xs: "1.3rem", sm: "1.7rem", md: "1.8rem" }}
          >
            Ultimate Fusion of Industrial
            <br />
            Functionality in a High Profile Location
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          {" "}
          <Typography
            color="#fff"
            fontSize={{ xs: "0.9rem", sm: "0.9rem", md: "1.1rem" }}
          >
            Each unit will feature allocated parking, high clearance
            roller doors and 3-Phase power. All units will be fitted
            with a mezzanine level as well as a bathroom and kitchenette,
            providing an exceptionally versatile face for your business.{" "}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        py={{ xs: 18, sm: 16, md: 22 }}
        px={{ xs: 6, sm: 6, md: 10 }}
        columnSpacing={12}
        rowSpacing={4}
      >
        <Grid item xs={12} sm={6} md={6}>
          {" "}
          <Typography
            variant="h4"
            color="rgb(0 198 94)"
            fontSize={{ xs: "1.3rem", sm: "1.7rem", md: "1.8rem" }}
          >
            ALL ACCESS. NO HASSLE.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          {" "}
          <Typography
            color="#fff"
            fontSize={{ xs: "0.9rem", sm: "0.9rem", md: "1.1rem" }}
          >
            Intelligent design and quality unit builds with common property car
            parking and amenities.{" "}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default DesignText;
