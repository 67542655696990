import React, { useEffect } from "react";
import { Update, Register, NavBar } from "../../components";
import BackButton from "../../components/UI/BackToTop/BackButton";
import { Box, Typography } from "@mui/material";

function UpdatePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <NavBar />
      <Box mt="130px" mx={8}>
        <Typography pb={6} variant="h4" textAlign="center" color="white">
          {" "}
          Timeline{" "}
        </Typography>
      </Box>
      <Update />
      <Register />
      <BackButton />
    </React.Fragment>
  );
}

export default UpdatePage;
