import React, { useEffect } from "react";
import {
  Overview,
  KeyFeatures,
  Welcome,
  Register,
  NavBar,
} from "../../components";
import Gallery from "../../components/Gallery/Gallery.jsx";
import BackButton from "../../components/UI/BackToTop/BackButton";
import "../../components/UI/Scrollbar/Scrollbar.css";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <NavBar />
      <Welcome />
      <Overview />
      <Register />
      <BackButton />
    </React.Fragment>
  );
}

export default Home;
