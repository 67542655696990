// import React from "react";
// import { Grid, Typography, Box } from "@mui/material";
// import Work from "../../assets/work-experience-1.svg";
// import industrial from "../../assets/industrial-2.svg";
// import agriculture from "../../assets/agriculture-6.svg";
// import business from "../../assets/business-22.svg";
// import assets from "../../assets/assets-6.svg";
// import freight from "../../assets/freight-car-01.svg";
// import rails from "../../assets/rail-15.svg";
// import plan from "../../assets/path-planning.svg";
// import classes from "./Elements.module.css";
// import Aos from "aos";
// import "aos/dist/aos.css";

// function OpportunityElt() {
//   React.useEffect(() => {
//     Aos.init({});
//   }, []);

//   return (
//     <Grid
//       container
//       rowSpacing={{ xs: 6, sm: 8, md: 12 }}
//       columnSpacing={{ xs: 1, sm: 2, md: 0 }}
//       py={16}
//       px={{ xs: 6, sm: 16, md: 22 }}
//       className={classes.grid_cont}
//       // justifyContent={"center"}
//       // alignItems={"center"}
//     >
//       <Grid
//         container
//         item
//         xs={12}
//         sm={12}
//         md={4}
//         textAlign="center"
//         rowSpacing={8}
//         columnSpacing={12}

//       >
//         {" "}
//         <Grid
//           item
//           xs={12}
//           sm={6}
//           md={12}
//           px={{ xs: 2, sm: 6, md: 8 }}
//           data-aos="fade-up"
//           data-aos-duration="3000"
//           container
//           justifyContent="center"
//           alignItems="center"
//         >
//           <Box maxWidth="220px" minWidth="220px" height="200px">
//             <Box height="100px">
//               <img
//                 src={Work}
//                 alt="work"
//                 height="90px"
//                 width="90px"
//                 className={classes.images}
//               />
//             </Box>
//             <Typography
//               color="white"
//               variant="body1"
//               fontSize="0.8rem"
//               pt={2}
//               className={classes.fonts}
//             >
//               Double the number of jobs in Nowra and provide a basis for
//               continued expansion of employment and income
//             </Typography>
//           </Box>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           sm={6}
//           md={12}
//           px={{ xs: 2, sm: 6, md: 8 }}
//           data-aos="fade-up"
//           data-aos-duration="3000"
//           container
//           justifyContent="center"
//           alignItems="center"
//         >
//           <Box maxWidth="220px" minWidth="220px" height="200px">
//             <Box height="100px">
//               <img
//                 src={industrial}
//                 alt="work"
//                 height="80px"
//                 width="80px"
//                 className={classes.images}
//               />
//             </Box>
//             <Typography
//               color="white"
//               variant="body1"
//               fontSize="0.8rem"
//               pt={2}
//               className={classes.fonts}
//             >
//               This Industrial Precinct would provide zero carbon low-cost energy
//               for industrial users, and water, land, transport and
//               communications
//             </Typography>
//           </Box>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={12}
//           px={{ xs: 2, sm: 6, md: 12 }}
//           data-aos="fade-up"
//           data-aos-duration="3000"
//           container
//           justifyContent="center"
//           alignItems="center"
//         >
//            <Box maxWidth="220px" minWidth="200px" height="200px">
//             <Box height="100px">
//             <img
//               src={agriculture}
//               alt="agri"
//               width="80px"
//               height="80px"
//               className={classes.images}
//             />
//             </Box>
//             <Typography
//               color="white"
//               variant="body1"
//               fontSize="0.8rem"
//               pt={2}
//               className={classes.fonts}
//             >
//               Demonstrate innovative zero carbon supply chains for agricultural
//               inputs and for processing agricultural and mineral products.
//             </Typography>
//           </Box>
//         </Grid>
//       </Grid>

//       <Grid
//         container
//         item
//         xs={12}
//         sm={12}
//         md={4}
//         my={{ xs: 2, sm: 4, md: 10 }}
//         rowSpacing={6}
//         textAlign="center"
//         columnSpacing={6}
//       >
//         {" "}
//         <Grid
//           item
//           xs={12}
//           sm={6}
//           md={12}
//           px={{ xs: 2, sm: 6, md: 12 }}
//           data-aos="fade-down"
//           data-aos-duration="3000"
//           container
//           justifyContent="center"
//           alignItems="center"
//         >
//           <Box maxWidth="220px" minWidth="200px" height="200px">
//             <Box height="100px">
//             <img
//               src={assets}
//               alt="assets"
//               width="80px"
//               height="80px"
//               className={classes.images}
//             />
//             </Box>

//             <Typography
//               color="white"
//               variant="body1"
//               fontSize="0.8rem"
//               pt={2}
//               className={classes.fonts}
//             >
//               Highly sustainable assets, those assets that deliver superior
//               environmental and indoor environmental outcomes for occupants
//             </Typography>
//           </Box>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           sm={6}
//           md={12}
//           px={{ xs: 2, sm: 6, md: 12 }}
//           data-aos="fade-down"
//           data-aos-duration="3000"
//           container
//           justifyContent="center"
//           alignItems="center"
//         >
//            <Box maxWidth="220px" minWidth="200px" height="200px">
//             <Box height="100px">
//             <img
//               src={business}
//               alt="business"
//               width="80px"
//               height="80px"
//               className={classes.images}
//             />
// </Box>
//             <Typography
//               color="white"
//               variant="body1"
//               fontSize="0.8rem"
//               pt={2}
//               className={classes.fonts}
//             >
//               This address is the region’s new home for high-performance
//               business, innovation, and success.
//             </Typography>
//           </Box>
//         </Grid>
//       </Grid>

//       <Grid
//         container
//         item
//         xs={12}
//         sm={12}
//         md={4}
//         textAlign="center"
//         rowSpacing={12}
//         columnSpacing={16}
//       >
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={12}
//           px={{ xs: 2, sm: 6, md: 10 }}
//           data-aos="fade-up"
//           data-aos-duration="3000"
//           container
//           justifyContent="center"
//           alignItems="center"
//         >
//            <Box maxWidth="220px" minWidth="200px" height="200px">
//             <Box height="100px">
//             <img
//               src={freight}
//               alt="freight"
//               width="80px"
//               height="80px"
//               className={classes.images}
//             />
// </Box>
//             <Typography
//               color="white"
//               variant="body1"
//               fontSize="0.8rem"
//               pt={2}
//               className={classes.fonts}
//             >
//               In addition to the growing population, the regional freight task
//               is expected to increase at an annual rate of 1.4% per annum over
//               the next 40 years, reaching roughly 62 million tones by 2056.{" "}
//             </Typography>
//           </Box>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           sm={6}
//           md={12}
//           px={{ xs: 2, sm: 6, md: 10 }}
//           data-aos="fade-up"
//           data-aos-duration="3000"
//           container
//           justifyContent="center"
//           alignItems="center"
//         >
//            <Box maxWidth="220px" minWidth="220px" height="200px">
//             <Box height="100px">
//             <img
//               src={rails}
//               alt="rails"
//               width="80px"
//               height="80px"
//               className={classes.images}
//             />
// </Box>
//             <Typography
//               color="white"
//               variant="body1"
//               fontSize="0.8rem"
//               pt={2}
//               className={classes.fonts}
//             >
//               Transport for NSW also commits to investigating improvements to
//               freight connections to Canberra and the Far South Coast, public
//               transport opportunities to Nowra and beyond, and fast rail for the
//               Sydney to Bomaderry corridor.
//             </Typography>
//           </Box>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           sm={6}
//           md={12}
//           px={{ xs: 2, sm: 6, md: 10 }}
//           data-aos="fade-up"
//           data-aos-duration="3000"
//           container
//           justifyContent="center"
//           alignItems="center"
//         >
//            <Box maxWidth="220px" minWidth="220px" height="200px">
//             <Box height="100px">
//             <img
//               src={plan}
//               alt="plan"
//               width="80px"
//               height="80px"
//               className={classes.images}
//             />
// </Box>
//             <Typography
//               color="white"
//               variant="body1"
//               fontSize="0.8rem"
//               pt={2}
//               className={classes.fonts}
//             >
//               The plan has been developed in conjunction with the Department of
//               Planning, Industry and Environment’s Illawarra-Shoalhaven Regional
//               Plan 2041.
//             </Typography>
//           </Box>
//         </Grid>
//       </Grid>
//     </Grid>
//   );
// }

// export default OpportunityElt;
import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import Work from "../../assets/work-experience-1.svg";
import industrial from "../../assets/industrial-2.svg";
import agriculture from "../../assets/agriculture-6.svg";
import business from "../../assets/business-22.svg";
import assets from "../../assets/assets-6.svg";
import freight from "../../assets/freight-car-01.svg";
import rails from "../../assets/rail-15.svg";
import plan from "../../assets/path-planning.svg";
import classes from "./Elements.module.css";
import Aos from "aos";
import "aos/dist/aos.css";

function OpportunityElt() {
  React.useEffect(() => {
    Aos.init({});
  }, []);

  return (
    <Grid
      container
      rowSpacing={{ xs: 6, sm: 8, md: 12 }}
      columnSpacing={{ xs: 1, sm: 2, md: 0 }}
      py={16}
      px={{ xs: 6, sm: 16, md: 22 }}
      className={classes.grid_cont}
    // justifyContent={"center"}
    // alignItems={"center"}
    >
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={4}
        textAlign="center"
        rowSpacing={8}
        columnSpacing={12}

      >
        {" "}
        <Grid
          item
          xs={12}
          sm={6}
          md={12}
          px={{ xs: 2, sm: 6, md: 8 }}
          data-aos="fade-up"
          data-aos-duration="3000"
          container
          justifyContent="center"
          alignItems="center"
        >
          <Box maxWidth="500px" minWidth="400px" height="100px">
            <Box height="50px" style={{ color: 'white', fontSize: 32 }}>
              The Opportunity
            </Box>
            <Typography
              color="white"
              variant="body1"
              fontSize="0.8rem"
              pt={2}
              className={classes.fonts}
            >
              The complex will offer buyers the perfect mix of
              commercial, industrial or lifestyle usages. There
              is opportunity for local tradespeople, established
              or start-up businesses, investors, downsizers, and
              lifestyle enthusiasts.
              <br /><br />
              Whether it is building your enterprise, engaging with
              the community, or discovering the freedom to work towards
              your creative and recreational pastimes. From storing your
              caravan, watercraft, or starting a new hobby in a secure
              location, Nowra Business Park is the right location for you.

            </Typography>
          </Box>
        </Grid>
        {/* <Grid
          item
          xs={12}
          sm={6}
          md={12}
          px={{ xs: 2, sm: 6, md: 8 }}
          data-aos="fade-up"
          data-aos-duration="3000"
          container
          justifyContent="center"
          alignItems="center"
        >
          <Box maxWidth="220px" minWidth="220px" height="200px">
            <Box height="100px">
              <img
                src={industrial}
                alt="work"
                height="80px"
                width="80px"
                className={classes.images}
              />
            </Box>
            <Typography
              color="white"
              variant="body1"
              fontSize="0.8rem"
              pt={2}
              className={classes.fonts}
            >
              This Industrial Precinct would provide zero carbon low-cost energy
              for industrial users, and water, land, transport and
              communications
            </Typography>
          </Box>
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          sm={12}
          md={12}
          px={{ xs: 2, sm: 6, md: 12 }}
          data-aos="fade-up"
          data-aos-duration="3000"
          container
          justifyContent="center"
          alignItems="center"
        >
          <Box maxWidth="220px" minWidth="200px" height="200px">
            <Box height="100px">
              <img
                src={agriculture}
                alt="agri"
                width="80px"
                height="80px"
                className={classes.images}
              />
            </Box>
            <Typography
              color="white"
              variant="body1"
              fontSize="0.8rem"
              pt={2}
              className={classes.fonts}
            >
              Demonstrate innovative zero carbon supply chains for agricultural
              inputs and for processing agricultural and mineral products.
            </Typography>
          </Box>
        </Grid> */}
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={4}
        my={{ xs: 2, sm: 4, md: 10 }}
        rowSpacing={6}
        textAlign="center"
        columnSpacing={6}
      >
        {" "}
        <Grid
          item
          xs={12}
          sm={6}
          md={12}
          px={{ xs: 2, sm: 6, md: 12 }}
          data-aos="fade-down"
          data-aos-duration="3000"
          container
          justifyContent="center"
          alignItems="center"
        >
          <Box maxWidth="220px" minWidth="200px" height="200px">
            <Box height="100px">
              {/* <img
                src={assets}
                alt="assets"
                width="80px"
                height="80px"
                className={classes.images}
              /> */}
            </Box>

            <Typography
              color="white"
              variant="body1"
              fontSize="0.8rem"
              pt={2}
              className={classes.fonts}
            >
              {/* Highly sustainable assets, those assets that deliver superior
              environmental and indoor environmental outcomes for occupants */}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={12}
          px={{ xs: 2, sm: 6, md: 12 }}
          data-aos="fade-down"
          data-aos-duration="3000"
          container
          justifyContent="center"
          alignItems="center"
        >
          <Box maxWidth="220px" minWidth="200px" height="200px">
            <Box height="100px">
              {/* <img
                src={business}
                alt="business"
                width="80px"
                height="80px"
                className={classes.images}
              /> */}
            </Box>
            <Typography
              color="white"
              variant="body1"
              fontSize="0.8rem"
              pt={2}
              className={classes.fonts}
            >
              {/* This address is the region’s new home for high-performance
              business, innovation, and success. */}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={4}
        textAlign="center"
        rowSpacing={12}
        columnSpacing={16}
      >
        {/* <Grid
          item
          xs={12}
          sm={12}
          md={12}
          px={{ xs: 2, sm: 6, md: 10 }}
          data-aos="fade-up"
          data-aos-duration="3000"
          container
          justifyContent="center"
          alignItems="center"
        >
          <Box maxWidth="220px" minWidth="200px" height="200px">
            <Box height="100px">
              <img
                src={freight}
                alt="freight"
                width="80px"
                height="80px"
                className={classes.images}
              />
            </Box>
            <Typography
              color="white"
              variant="body1"
              fontSize="0.8rem"
              pt={2}
              className={classes.fonts}
            >
              In addition to the growing population, the regional freight task
              is expected to increase at an annual rate of 1.4% per annum over
              the next 40 years, reaching roughly 62 million tones by 2056.{" "}
            </Typography>
          </Box>
        </Grid> */}
        <Grid
          item
          xs={12}
          sm={6}
          md={12}
          px={{ xs: 2, sm: 6, md: 10 }}
          data-aos="fade-up"
          data-aos-duration="3000"
          container
          justifyContent="center"
          alignItems="center"
        >
          <Box maxWidth="500px" minWidth="400px" height="300px">
            <Box height="50px" style={{ color: 'white', fontSize: 30 }}>
              Investing in the Shoalhaven
            </Box>
            <Typography
              color="white"
              variant="body1"
              fontSize="0.8rem"
              pt={2}
              className={classes.fonts}
            >
              As one of Australia’s fastest growing regional areas,
              economic growth is following high population growth
              to provide a rich array of investment opportunities
              – ranging from investing in existing businesses;
              commercial or residential property development;
              to investing in your own start up business.
              The lower cost of doing business in the Shoalhaven
              compared to Sydney or other capital cities also offers
              a compelling reason to invest in the Shoalhaven.
              <br /><br/>
              A wide range of industries operate in the Shoalhaven with many
              international businesses operating from Nowra. Value adding
              manufacturing is strong, with food, chemicals, construction
              products, marine craft and other manufactured componentry.
              High technology companies provide aviation maintenance and
              systems support to the Royal Australian Navy. The logistics
              sector is growing as are education, building & construction,
              retail and tourism.

            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={12}
          px={{ xs: 2, sm: 6, md: 10 }}
          data-aos="fade-up"
          data-aos-duration="3000"
          container
          justifyContent="center"
          alignItems="center"
        >
          <Box maxWidth="400px" minWidth="400px" height="200px">
            <Box height="100px">
              {/* <img
                src={plan}
                alt="plan"
                width="80px"
                height="80px"
                className={classes.images}
              /> */}
            </Box>
            <Typography
              color="white"
              variant="body1"
              fontSize="0.8rem"
              pt={2}
              className={classes.fonts}
            >
             
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OpportunityElt;
