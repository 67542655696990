import React, { useEffect } from "react";
import { Stack, Grid, Typography, Box } from "@mui/material";
import classes from "./Location.module.css";
import map from "../../assets/Group 106.png";
import NavBar from "../NavBar/NavBar";
import Register from "../Register/Register2.jsx";
import Work from "../../assets/location.jpg";
import imgback from "../../assets/Ellipse 54.png";
import imgback1 from "../../assets/Ellipse 54 (1).png";
import imgback2 from "../../assets/Ellipse 64 2.png";
import imgback3 from "../../assets/Ellipse 64.png";
import BackButton from "../../components/UI/BackToTop/BackButton";

const Location = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="locations">
      <NavBar />
      <Box mt="130px" mx={8}>
        <Typography pb={6} variant="h3" textAlign="center" color="white">
          {" "}
          Location{" "}
        </Typography>

      </Box>

      <Grid container lg={12} md={12} sm={12} textAlign='center' justifyContent='center'>
        <Grid item lg={8} md={8} sm={8} justifyContent='center' textAlign='center'>
          <Typography color="white" style={{ fontSize: 21.15, fontWeight: 300, fontFamily: 'Rubik', lineHeight: '35.25px' }}>
            {" "}

            {/* Revesby is one of Sydney’s most established industrial precincts
            and is situated within one of Sydney’s biggest growth corridors,
            making it ideal for any owner occupier or investor.
            <br /><br /> */}
            South Nowra is well-connected to nearby towns and cities, making it an easily accessible location. With proximity to major arterial roads and highways providing easy access to transportation routes. Nowra serves as the commercial and administrative centre of the Shoalhaven region and offers additional amenities, educational institutions, and employment opportunities.{" "}
          </Typography>
        </Grid>
      </Grid>
      <Grid container lg={12} md={12} sm={12} justifyContent='center' style={{ padding: 100 }}>
        <Grid item lg={3} md={3} sm={11} justifyContent='center' >
          <p style={{ color: 'white', fontSize: 43, fontWeight: 700 }}>Retail</p>
          <Grid>
            <img src={imgback} style={{ position: 'absolute' }} />

            <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>1
              <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Burnings</p></p>
          </Grid>
          <Grid style={{ marginTop: -50 }}>
            <img src={imgback} style={{ position: 'absolute' }} />
            <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>2
              <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Supercheap Auto </p></p>
            <Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>3
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Harvey Nonman </p></p>

              </Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>4
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>BCF </p></p>
              </Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>5
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Fantastic Future </p></p>
              </Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>6
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Stockland Nowra </p></p>
              </Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>7
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Coles</p></p>
              </Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>8
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Woolworths</p></p>
              </Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>9
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Dan Murphy’s</p></p>
              </Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 8, position: 'relative' }}>10
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Sydney Tools</p></p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={3} md={3} sm={11} justifyContent='center' >
          <p style={{ color: 'white', fontSize: 43, fontWeight: 700 }}>Lifestyle</p>
          <Grid>
            <img src={imgback1} style={{ position: 'absolute' }} />

            <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>1
              <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Subway</p></p>
          </Grid>
          <Grid style={{ marginTop: -50 }}>
            <img src={imgback1} style={{ position: 'absolute' }} />
            <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>2
              <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Hungry Jacks </p></p>
            <Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback1} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>3
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Cafe Rene </p></p>

              </Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback1} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>4
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Earnest Arthur  </p></p>
              </Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback1} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>5
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Mcdonalds </p></p>
              </Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback1} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>6
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Oporto </p></p>
              </Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback1} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>7
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>KFC</p></p>
              </Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback1} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>8
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Java Life Drive Thru</p></p>
              </Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback1} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>9
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>GYG Nowra</p></p>
              </Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback1} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 8, position: 'relative' }}>10
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Sydney Tools</p></p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={3} md={3} sm={11} justifyContent='center' >
          <p style={{ color: 'white', fontSize: 43, fontWeight: 700 }}>Medical</p>
          <Grid>
            <img src={imgback2} style={{ position: 'absolute' }} />

            <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>1
              <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Shoalhaven District Hospital</p></p>
          </Grid>
          <Grid style={{ marginTop: -50 }}>
            <img src={imgback2} style={{ position: 'absolute' }} />
            <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>2
              <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Nowra Private School </p></p>

            <Grid style={{ marginTop: -50 }}>
              <img src={imgback2} style={{ position: 'absolute' }} />
              <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>3
                <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Abatross Medical Centre </p></p>

            </Grid>
            <p style={{ color: 'white', fontSize: 43, fontWeight: 700 }}>Transport</p>
            <Grid >
              <img src={imgback2} style={{ position: 'absolute' }} />
              <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>1
                <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Bomaderry Station </p></p>
            </Grid>
            <Grid style={{ marginTop: -50 }}>
              <img src={imgback2} style={{ position: 'absolute' }} />
              <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>2
                <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Nowra Airport </p></p>
            </Grid>

          </Grid>
        </Grid>

        <Grid item lg={3} md={3} sm={11} justifyContent='center' >
          <p style={{ color: 'white', fontSize: 43, fontWeight: 700 }}>Education</p>
          <Grid>
            <img src={imgback3} style={{ position: 'absolute' }} />

            <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>1
              <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Nowra Christian School</p></p>
          </Grid>
          <Grid style={{ marginTop: -50 }}>
            <img src={imgback3} style={{ position: 'absolute' }} />
            <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>2
              <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Shoalhaven High School</p></p>
            <Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback3} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>3
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>UOW Shoalhaven Campus</p></p>

              </Grid>
              <Grid style={{ marginTop: -50 }}>
                <img src={imgback3} style={{ position: 'absolute' }} />
                <p style={{ color: 'white', top: -2, left: 12, position: 'relative' }}>4
                  <p style={{ color: 'white', top: -30, left: 32, position: 'relative' }}>Nowra Public School</p></p>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.map__background}>
        <img src={map} alt="Map" width="100%" />
        {/* <span></span> */}
      </div>

      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          {" "}
          <Stack py={10} px={{ xs: 4, sm: 16, md: 16 }} backgroundColor="#191919">
            <Typography variant="h5" color="rgb(0 198 94)" pb={3}>
              HIGH PROFILE LOCATION
            </Typography>
            <Typography color="#fff">
              South Nowra is well-connected to nearby towns and cities,
              making it an easily accessible location. With proximity
              to major arterial roads and highways providing easy access
              to transportation routes. Nowra serves as the commercial
              and administrative centre of the Shoalhaven region and offers
              additional amenities, educational institutions, and employment
              opportunities.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Stack py={10} px={{ xs: 4, sm: 16, md: 16 }}>
            <Typography variant="h5" color="rgb(0 198 94)" pb={3}>
              GOOD COMPANY
            </Typography>
            <Typography color="#fff">
              Be among some of the most successful businesses who have mastered
              their craft in Australia, fuelling your confidence, profile and
              lifestyle.
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <div>
        <img src={Work} alt="Map" width="100%" />
      </div>
      <Stack
        py={10}
        px={{ xs: 4, sm: 4, md: 18 }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h5" color="rgb(0 198 94)" pb={3}>
          STRATEGICALLY LOCATED OFFERING CONVENIENCE NEAR NOWRA CBD
        </Typography>
        <Box
          width={{ xs: "100%", sm: "70%", md: "55%" }}
          textAlign={{ xs: "left", sm: "center", md: "center" }}
        >
          <Typography color="#fff">
            The location also provides opportunities for commercial and industrial
            activities, making it an appealing destination for businesses and entrepreneurs.
            The region benefits from reliable transportation
            networks, including road access and public transportation options.
            This ensures ease of travel within the area and beyond.
          </Typography>
        </Box>
      </Stack>
      <Stack
        py={10}
        px={{ xs: 4, sm: 4, md: 18 }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h5" color="rgb(0 198 94)" pb={3}>
          DOING BUSINESS IN THE SHOALHAVEN
        </Typography>
        <Box
          width={{ xs: "100%", sm: "70%", md: "55%" }}
          textAlign={{ xs: "left", sm: "center", md: "center" }}
        >
          <Typography color="#fff">
            The Shoalhaven’s growing population and proximity
            to Sydney, Wollongong and Canberra gives your
            business access to markets of 6 million people.
            With a population of over 100,000 people,
            the Shoalhaven provides investors and
            operators with a city sized customer
            and business base offering incredible
            opportunities across a range of different sectors.
            <br />
            <br />
            The Shoalhaven offers its residents the rare opportunity
            to grow a business with quality employees and business
            networks while still enjoying the benefits and lifestyle
            of country and coastal living. These business opportunities
            are only expected to increase over the next few years,
            with the Shoalhaven remaining one of the fastest growing
            areas in Australia.

          </Typography>
        </Box>
      </Stack>
      <Register />
      <BackButton />
    </div>
  );
};

export default Location;
