import React, { useEffect } from "react";
import Aos from "aos";
import { Box, Grid, Stack } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import img1 from "../../../assets/locationReplaced.jpg";
import img2 from "../../../assets/KeyFeaturesReplaced.jpg";
import img3 from "../../../assets/buildingReplaced.jpg";
import img4 from "../../../assets/header-replaced.jpg";
import img5 from "../../../assets/Image6.jpg";

import classes from "./DesignTab.module.css";
import Slider from "react-slick";

// import TabGallery from "./TabGallery";

const AntTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "rgb(0 198 94)",
  },
});

const AntTab = styled(Tab)({
  textTransform: "capitalize",
  fontSize: "0.9rem",
  color: "white",
  "&:hover": {
    color: "rgb(0 198 94)",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "rgb(0 198 94)",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgb(0 198 94)",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box mt={5}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DesignTab() {
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    Aos.init({});
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: "linear",
    autoplay: true,
    autoplaySpeed: 1500,
  };


  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };
  return (

    <Stack mb={14} mt="85px">
      <Grid container spacing={1.5} p={4} backgroundColor="rgb(25 25 25)">
        <div className="fade-carousel-container" style={{ width: '100%' }}>
          <Slider className="fade-carousel" {...settings}>
            <img className="carousel-item" src={img1} alt="nature" />
            <img className="carousel-item" src={img2} alt="nature" />
            <img className="carousel-item" src={img3} alt="nature" />
            <img className="carousel-item" src={img4} alt="nature" />
            <img className="carousel-item" src={img5} alt="nature" />
          </Slider>
        </div>
      </Grid>
      {/* <Box pt={5} display="flex" justifyContent="center" width="100%">
        <AntTabs
          centered
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          opacity="1"
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
          className={classes.tabs}
        >
          <AntTab label="Pro Services" color="white" {...a11yProps(0)} />
          <AntTab label="E-Commerce" {...a11yProps(1)} />
          <AntTab label="Importers" {...a11yProps(2)} />
          <AntTab label="Wholesalers" {...a11yProps(3)} />
          <AntTab label="Craftspeople" {...a11yProps(4)} />
          <AntTab label="Trade" {...a11yProps(5)} />
          <AntTab label="Manufacturing" {...a11yProps(6)} />
        </AntTabs>
      </Box> */}
      <TabPanel value={value} index={0}>
        <Stack
          px={{ base: 12, md: 22 }}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          {/* <TabGallery /> */}
          <Box align="center">
            <img
              src={img1}
              alt="1"
              style={{
                width: "1500px",
                height: "525px",
              }}
              data-aos="fade-up"
              data-aos-duration="3000"
              className={classes.images}
            />
          </Box>
        </Stack>
      </TabPanel>
      <TabPanel value={value} index={1} sx={{ visibility: "visible" }}>
        <Stack
          px={{ base: 12, md: 22 }}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Box>
            <img
              src={img2}
              alt="1"
              style={{
                width: "1050px",
                height: "525px",
              }}
              data-aos="fade-up"
              data-aos-duration="3000"
              className={classes.images}
            />
          </Box>
        </Stack>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Stack
          px={{ base: 12, md: 22 }}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Box>
            <img
              src={img3}
              alt="1"
              style={{
                width: "1050px",
                height: "525px",
              }}
              data-aos="fade-up"
              data-aos-duration="3000"
              className={classes.images}
            />
          </Box>
        </Stack>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Stack
          px={{ base: 12, md: 22 }}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Box>
            <img
              src={img4}
              alt="1"
              style={{
                width: "1050px",
                height: "525px",
              }}
              data-aos="fade-up"
              data-aos-duration="3000"
              className={classes.images}
            />
          </Box>
        </Stack>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Stack
          px={{ base: 12, md: 22 }}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Box>
            <img
              src={img1}
              alt="1"
              style={{
                width: "1050px",
                height: "525px",
              }}
              data-aos="fade-up"
              data-aos-duration="3000"
              className={classes.images}
            />
          </Box>
        </Stack>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Stack
          px={{ base: 12, md: 22 }}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Box>
            <img
              src={img2}
              alt="1"
              style={{
                width: "1050px",
                height: "525px",
              }}
              data-aos="fade-up"
              data-aos-duration="3000"
              className={classes.images}
            />
          </Box>
        </Stack>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Stack
          px={{ base: 12, md: 22 }}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Box>
            <img
              src={img3}
              alt="1"
              style={{
                width: "1050px",
                height: "525px",
              }}
              data-aos="fade-up"
              data-aos-duration="3000"
              className={classes.images}
            />
          </Box>
        </Stack>
      </TabPanel>
    </Stack>
  );
}
