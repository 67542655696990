import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Stack, Grid, Box, Typography, styled } from "@mui/material";
import Address from "../../assets/address.png";
import Council from "../../assets/council.png";
import Zoning from "../../assets/zoning.png";
import Sizes from "../../assets/sizes.png";
import Prices from "../../assets/prices.png";
import Completion from "../../assets/completion.png";
import Inspection from "../../assets/inspection.png";
import classes from "./Overview.module.css";

const images = [
  {
    icon: Address,
    title: "Various sized options",
    text: "7 Nowra Hill Road, South Nowra NSW 2541"
  },
  {
    icon: Council,
    title: "High profile location",
    text: "Shoalhaven City Council"
  },
  {
    icon: Completion,
    title: "Flexible layout options",
    text: "Late 2024"
  },
  {
    icon: Zoning,
    title: "Zoning E4 General Industrial under Shoalhave LEP",
    text: "IN1 General Industrial"
  },
  {
    icon: Sizes,
    title: "Close proximity to Nowra CBD",
    text: "From 150m2 to 1000m2"
  },
  {
    icon: Prices,
    title: "Allocated and additional parking onsite",
    text: "From $640,000 + GST"
  },
  // {
  //   icon: Inspection,
  //   title: "Inspection",
  //   text: "By appointment only",
  //   width: "50px",
  //   height: "50px",
  // },
];

const AspectRatioImg = styled("img")`
  aspect-ratio: 3/2;
  object-fit:contain;
`;

const FlexRow = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items:flex-start;
  justify-content:center;
  margin-left:auto;
  margin-right:auto;
  gap:15px;
`;

const FlexColumn = styled("div")`
  display: flex;
  flex-direction: column;
  width:190px;
  align-items:center;
  justify-content:center;
`;

const Image = () => {
  useEffect(() => {
    Aos.init({});
  }, []);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      py={{ xs: 4, sm: 4, md: 6 }}
      sx={{
        backgroundColor: "#191919",
        flexDirection: "row",
      }}
      id="overview"
    // overflowX="hidden"
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          textAlign="center"
          data-aos="fade-right"
          data-aos-duration="3000"
        >
          <Box
            sx={{
              textAlign: "center",
            }}
            px={{ xs: 4, sm: 14, md: 22 }}
            pb={{ xs: 4, sm: 4, md: 8 }}
          >
            <Typography
              color="rgb(0 198 94)"
              fontWeight="bold"
              py={4}
              sx={{ fontSize: { xs: "1.8rem", sm: "2rem", md: "2.7rem" } }}
            >
              Overview
            </Typography>
            <Typography
              variant="body1"
              color="white"
              lineHeight={1.7}
              pb={4}
              sx={{ fontSize: { xs: "1rem", sm: "1.2rem", md: "1.15rem" } }}
            >
              Nowra Business Park will consist of 68 warehouses
              and combine intelligent design and quality build
              with ample parking and amenities. The complex will
              offer buyers the perfect mix of commercial,
              industrial or lifestyle usages.
            </Typography>
          </Box>
        </Grid>

        <FlexRow>
          {images.map((image, index) => (
            <FlexColumn
              data-aos="fade-up"
              data-aos-duration="3000"
              key={index}
            >
              <AspectRatioImg
                src={image.icon}
                alt={image.title}
                width={"50%"}
              />
              <Typography
                pt={1}
                color="white"
                textAlign={"center"}
                variant="h5"
                fontWeight="bold"
                sx={{ fontSize: { xs: "1.8rem", sm: "1.4rem", md: "1.3rem" } }}
                className={classes.title}
              >
                {image.title}
              </Typography>
            </FlexColumn>
          ))}
        </FlexRow>
      </Grid>
    </Stack>
  );
};

export default Image;
