import React, { useEffect } from "react";
// import "./welcome.css";
import classes from "./SMSF.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { HashLink as Link } from "react-router-hash-link";


function SMSF() {
  useEffect(() => {
    Aos.init({});
  }, []);

  return (
    <div className={classes.bg_wlc}>
      <div data-aos="fade-right" data-aos-duration="3000">
        <h1 className={classes.wlc_txt}>A Self-Managed</h1>
        <h1 className={classes.wlc_txt}>
          <span style={{ color: "rgb(0 198 94)" }}>Superannuation</span> Fund
        </h1>
        <div className={classes.container}>
          <p className={classes.txt}>
            SMSF is a type of superannuation fund in Australia that is managed
            by its members. SMSFs provide individuals with the ability to
            control their retirement savings and make investment decisions on
            their own behalf. While SMSFs can invest in a wide range of assets,
            including property, it is essential to understand the rules and
            regulations surrounding SMSF investments.
          </p>
        </div>
      </div>

      <div className={classes.btns}>
        <div class={classes.arrow_container}>
          <Link to="#toscroll">
            <div class={classes.chevron}></div>
            <div class={classes.chevron}></div>
            <div class={classes.chevron}></div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SMSF;
