import React, { useEffect } from "react";
import "./welcome.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Button } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
import plan from "../../../assets/Files/Final Info 403 -2022-FR.pdf";

const Welcome = () => {
  useEffect(() => {
    Aos.init({});
  }, []);

  const enquireBtn = {
    color: "#000",
    border: " 3px solid rgb(0 198 94)",
    backgroundColor: "rgb(0 198 94)",
    fontWeight: "bold",
    borderBottomRightRadius: "15px",
    borderTopLeftRadius: "15px",
    px: 3.7,
    py: 1.5,
    fontSize: { xs: "11px", sm: "13px", md: "18px" },
    mb: { xs: 2, sm: 0, md: 0 },
    "&:hover": {
      color: "rgb(0 198 94)",
      border: " 2px solid rgba(149,239,164,0.6)",
      backgroundColor: "rgba(149,239,164,0.6)",
    },
  };
  const handleDownload = () => {
    // Replace "path/to/pdf/file.pdf" with the actual URL of your PDF file
    const fileUrl = plan;
    window.open(fileUrl, "_blank");
  };
  return (
    <div className="bg-wlc">
      <div data-aos="fade-right" data-aos-duration="3000">
        <h1 className="wlc-txt">
          {" "}
          A<span style={{ color: "rgb(0 198 94)" }}> New Age</span> Of Business
        </h1>
        <h1 className="wlc-txt"></h1>
        <div>
          <p className="txt">
            A high profile location in the heart of Shoalhaven’s large format retail precinct.
            <br />
            This is your opportunity to position yourself among some of Australia’s national, large format operators. <br />
          </p>
        </div>
      </div>

      <div className="btns">

        <Button
          sx={enquireBtn}
          data-aos="flip-right"
          data-aos-duration="3000"
        >
          <Link
            to="#register"
            style={{ color: "rgb(7 29 73)", textDecoration: "none" }}
            smooth
          >
            ENQUIRE NOW
          </Link>
        </Button>
{/* 
        <Button
          sx={{
            color: "rgb(0 198 94)",
            border: " 3px solid rgb(0 198 94)",
            backgroundColor: "none",
            fontWeight: "bold",
            borderBottomRightRadius: "15px",
            borderTopLeftRadius: "15px",
            px: 3.7,
            py: 1.5,
            mb: { xs: 2, sm: 0, md: 0 },
            fontSize: { xs: "11px", sm: "13px", md: "18px" },
          }}
          className="btn-hover-empty"
          data-aos="flip-left"
          data-aos-duration="3000"
          onClick={handleDownload}
        >
          {" "}
          Floor Plan
        </Button> */}
      </div>
    </div>
  );
};

export default Welcome;
