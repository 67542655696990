import React from "react";
import data from "../Data/data.json";
import "./Timeline.css";
import img1 from "../../assets/locationReplaced.jpg";
import img2 from "../../assets/KeyFeaturesReplaced.jpg";
import img3 from "../../assets/buildingReplaced.jpg";
import * as MuiIcons from "@mui/icons-material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function UpdateTimeLine() {
  const settings = {
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: "linear",
  };
  const getIconComponent = (iconName) => {
    const Icon = MuiIcons[iconName];
    if (Icon) {
      return <Icon className="icon" />;
    } else {
      return null;
    }
  };

//   const protocol = "http://";

  const content = data.map((item, index) => (
    <div key={item.id} data-testid="timeline-item">
      {item.id % 2 !== 0 && (
        <div className="column-left ">
          <div className="container-date">
            <div className="small-size"></div>
            <div className="date-box">
              {getIconComponent(item.icon)}
              {item.note}
            </div>
          </div>
          <div className="col-card-left">
            <div className="col-card-title col-card-title-left">
              {item.title}
            </div>
            <div className="fade-carousel-container">
              <Slider className="fade-carousel" {...settings}>
                <img className="carousel-item" src={img1} alt="nature" />
                <img className="carousel-item" src={img2} alt="nature" />
                <img className="carousel-item" src={img3} alt="nature" />
              </Slider>
            </div>{" "}
            <div className="col-card-para">{item.para}</div>
            {/* <div className="read-box">
              <button
                className="numberCircle"
                href={protocol + item.url}
                target="_blank"
              >
                Read More
              </button>
            </div> */}
          </div>
        </div>
      )}
      {item.id % 2 !== 0 && (
        <div className="column-right">
          <div className="col-note col-note-right">
            <p>{item.note}</p>
          </div>
        </div>
      )}

      <div className="column-center">
        {item.id % 2 !== 0 && <div className="triangle-left"></div>}
        {item.id % 2 === 0 && <div className="triangle-right"></div>}

        <div className="line"></div>
        <div className="circle">{getIconComponent(item.icon)}</div>
      </div>

      {item.id % 2 === 0 && (
        <div className="column-right">
          <div className="container-date">
            <div className="small-size"></div>
            <div className="date-box">
              {getIconComponent(item.icon)}
              {item.note}
            </div>
          </div>
          <div className="col-card-right">
            <div className="col-card-title col-card-title-right">
              {item.title}
            </div>
            <Slider className="fade-carousel" {...settings}>
              <img className="carousel-item" src={img1} alt="nature" />
              <img className="carousel-item" src={img2} alt="nature" />
              <img className="carousel-item" src={img3} alt="nature" />
            </Slider>
            <div className="col-card-para">{item.para}</div>
            {/* <div className="read-box">
              <button
                className="numberCircle"
                href={protocol + item.url}
                target="_blank"
              >
                Read More
              </button>
            </div> */}
          </div>
        </div>
      )}
      {item.id % 2 === 0 && (
        <div className="column-left">
          <div className="col-note col-note-left">
            <p>{item.note}</p>
          </div>
        </div>
      )}
      <div className="clearfix"></div>
    </div>
  ));

  return (
    <div className="timeline" data-testid="timeline_id">
      {content}
    </div>
  );
}

export default UpdateTimeLine;
