import React, { useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import Register from "../../components/Register/Register2";
import OppElt from "../../components/Opportunity/OpportunityElt";
import img1 from "../../assets/Image4.jpg";
import img2 from "../../assets/Image7.jpg";
import img3 from "../../assets/Image9.jpg";
import img4 from "../../assets/Image12.jpg";
import img5 from "../../assets/Image8.jpg";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BackButton from "../../components/UI/BackToTop/BackButton";
import { Grid } from "@mui/material";

function Opportunity() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: "linear",
    autoplay: true,
    autoplaySpeed: 1500,
  };

  return (
    <React.Fragment>
      <NavBar />
    
        <div className="fade-carousel-container" style={{ width: '100%',marginTop:170 }}>
          <Slider className="fade-carousel" {...settings}>
            <img className="carousel-item" src={img1} alt="nature" />
            <img className="carousel-item" src={img2} alt="nature" />
            <img className="carousel-item" src={img3} alt="nature" />
            <img className="carousel-item" src={img4} alt="nature" />
            <img className="carousel-item" src={img5} alt="nature" />
          </Slider>
        </div>
  
      <OppElt />
      <Register />
      <BackButton />
    </React.Fragment>
  );
}

export default Opportunity;
