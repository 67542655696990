import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Stack, Box, Typography } from "@mui/material";

function SMSMContent({ title, text, source, title2, text2, source2 }) {
  useEffect(() => {
    Aos.init({});
  }, []);
  return (
      <Box >
        <Stack
          direction={{ base: "column", sm:"column", md: "row" }}
          width="100%"
          overflowX="hidden"
        >
          <Box
            width={{ base: "100%", sm: "full", md: "50%" }}
            px={{xs:4,sm:6,md:10}}
            py={{xs:6,sm:6,md:10}}
            justifyContent="center"
            display="flex"
            flexDirection="column"
            backgroundColor="rgb(25 25 25)"
            data-aos="fade-down"
            data-aos-duration="3000"
          >
            <Typography
              variant="h4"
              color="rgb(0 198 94)"
              fontSize={{ xs: "1.3rem", sm: "1.7rem", md: "1.8rem" }}
              pb={4}
            >
              {title}
            </Typography>
            <Typography color="white" sx={{ lineHeight: "30px" }}>
              {text}
            </Typography>
          </Box>

          <Box
            width={{ base: "100%", sm: "full", md: "60%" }}
            py={4}
            overflowX="hidden"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            data-aos-duration="3000"
            data-aos="zoom-in-up"
          >
            <img
              src={source}
              alt="SMSMContent"
              style={{
                width: "70%",
                height: "auto",
              }}
            />
          </Box>
        </Stack>
        <Stack
          direction={{ base: "column", sm:"column", md: "row" }}
          width="100%"
          overflowX="hidden"
        >
          <Box
            width={{ baae: "100%", sm: "full", md: "50%" }}
            px={{xs:4,sm:6,md:10}}
            py={{xs:6,sm:6,md:10}}
            justifyContent="center"
            display="flex"
            flexDirection="column"
            backgroundColor="rgb(25 25 25)"
            data-aos="fade-right"
            data-aos-duration="3000"
          >
            <Typography
              variant="h4"
              color="rgb(0 198 94)"
              fontSize={{ xs: "1.3rem", sm: "1.7rem", md: "1.8rem" }}
              pb={4}
            >
              {title2}
            </Typography>
            <Typography color="white" sx={{ lineHeight: "30px" }}>
              {text2}
            </Typography>
          </Box>

          <Box
            width={{ base: "100%", sm: "full", md: "60%" }}
            py={4}
            overflowX="hidden"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            data-aos-duration="3000"
            data-aos="zoom-in-right"
          >
            <img
              src={source2}
              alt="SMSMContent"
              style={{
                width: "70%",
                height: "auto",
              }}
            />
          </Box>
        </Stack>
      </Box>
  );
}

export default SMSMContent;
