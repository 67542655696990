import React, { useEffect } from "react";
import { SMSF, Register, NavBar } from "../../components";
import SMSFList from "../../components/SMSF/SMSFList";
import BackButton from "../../components/UI/BackToTop/BackButton";
import { Box } from "@mui/material";

function SMSFPack() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <NavBar />
      <SMSF />
      <Box my={14}>
        <SMSFList />
      </Box>
      <Register />
      <BackButton />
    </React.Fragment>
  );
}

export default SMSFPack;
