import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home.jsx";
import Opportunity from "./pages/Opportunity/Opportunity.jsx";
import Design from "./pages/Design/Design.jsx";
import SMSFPack from "./pages/SMSFPack/SMSFPack.jsx";
import UpdatePage from "./pages/UpdatePage/UpdatePage";
import { Location } from "./components";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route path="/location" element={<Location />} />
          <Route path="/opportunity" element={<Opportunity/>}/>
          <Route path="/design" element={<Design/>}/>
          <Route path="/smsf" element={<SMSFPack/>}/>
          <Route path="/update" element={<UpdatePage/>}/>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
